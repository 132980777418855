import React from "react"
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon, Heading } from "@chakra-ui/core"

import { ICategoryProps } from "components/lib/CategoryLink"
import Link from "components/lib/Link"

interface IContentHeaderProps {
  breadcrumbs: ICategoryProps[]
  title?: string
}

export const ContentHeader: React.StatelessComponent<IContentHeaderProps> = ({ breadcrumbs, title }) => (
  <div>
    {breadcrumbs.length !== 0 && (
      <Box padding={2} bg="gray.100">
        <Breadcrumb spacing="8px" separator={<Icon color="gray.600" name="chevron-right" />}>
          <BreadcrumbItem>
            <Link renderedComponent={BreadcrumbLink} href="/">
              Потеряшки
            </Link>
          </BreadcrumbItem>
          {breadcrumbs.slice(0, breadcrumbs.length - 1).map((b, i) => (
            <BreadcrumbItem key={i}>
              <Link renderedComponent={BreadcrumbLink} href={b.url}>
                {b.name}
              </Link>
            </BreadcrumbItem>
          ))}
          <BreadcrumbItem isCurrentPage>
            <Link renderedComponent={BreadcrumbLink} href="#">
              {breadcrumbs[breadcrumbs.length - 1].name}
            </Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
    )}
    {title && (
      <Heading padding={2} as="h1">
        {title}
      </Heading>
    )}
  </div>
)
