import React from "react"
import { push as pushLocation, Push } from "connected-react-router"
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/core"
import { connect } from "react-redux"

interface IOwnProps extends ButtonProps {
  href?: string
  rel?: string
}

interface IDispatchProps {
  push: Push
}

interface IProps extends IOwnProps, IDispatchProps {}

const mapDispatchToProps = {
  push: pushLocation,
}

const Button = ({ href, onClick, push, children, ...restProps }: IProps) => (
  <ChakraButton as={restProps.rel ? `a` : undefined} onClick={href ? () => push(href) : onClick} {...restProps}>
    {children}
  </ChakraButton>
)

export default connect<{}, IDispatchProps, IOwnProps>(undefined, mapDispatchToProps)(Button)
