import React from "react"
import Link from "components/lib/Link"

export interface ICategoryProps {
  name: string
  url: string
  icon?: JSX.Element
}

export class CategoryLink extends React.Component<ICategoryProps> {
  public render() {
    return (
      <Link href={this.props.url}>
        {this.props.icon && this.props.icon}
        {(this.props.icon ? " " : "") + this.props.name}
      </Link>
    )
  }
}
