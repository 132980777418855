import React from "react"
import Script from "react-load-script"
import { initWidgets, widgets } from "modules/vk"

interface IProps {
  onLoad: (widgets: any) => void
}

const VkLoader = React.memo(({ onLoad }: IProps) => {
  if (typeof window === "undefined") {
    return
  }

  const w = window as any
  if (w.foundpetsVkWasLoaded) {
    console.info(`VkLoader: vk.com was already loaded, skip`)
    onLoad(widgets())
    return
  }

  return (
    <Script
      url="//vk.com/js/api/openapi.js?168"
      onError={() => console.error(`failed to load vk script`)}
      onLoad={() => {
        console.info(`loaded vk script`)
        const initedWidgets = initWidgets()
        w.foundpetsVkWasLoaded = true
        onLoad(initedWidgets)
      }}
      attributes={{ async: false, defer: true }}
    />
  )
})

export default VkLoader
