/** @jsx jsx */
import React from "react"
import { Box } from "@chakra-ui/core"
import { css, jsx } from "@emotion/core"
import GatsbyImage from "gatsby-image"
import LazyLoad from "react-lazyload"
import { IImageMetadata } from "modules/ads/ads.reducers"
import { fixedSizeUrl, originalSizeUrl } from "modules/ads/images"

export interface IPetImageProps {
  isLazy: boolean
  metadata: IImageMetadata
  viewPreset: IViewPreset
  alt: string
  description: string
  onClick: () => void
}

interface IItemProp {
  name: string
  value: string
}

const ItemProp = ({ name, value }: IItemProp) => (
  <span
    css={css`
      display: none;
    `}
    itemProp={name}
  >
    {value}
  </span>
)

interface IViewPreset {
  widthPx: number
  heightPx?: number
  isFixed: boolean
}

// device to pixel ratio (dpr), must be in sync with deploy/nginx config to allow imgproxy urls
const supportedDprs = [1, 1.5, 2, 3, 4]

export const viewPresetDesktop: IViewPreset = {
  widthPx: 270,
  heightPx: 203,
  isFixed: true,
}

export const viewPresetMobile: IViewPreset = {
  widthPx: 414, // 85% of mobile screens <= 414px by ya.metrika at 07.2020
  isFixed: false,
}

interface ISizedUrls {
  [multiplier: number]: string
}

interface IImageSources {
  originalSrc: string
  jpeg: ISizedUrls
  webp: ISizedUrls
}

class PetImage extends React.PureComponent<IPetImageProps> {
  private onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.onClick()
  }

  private buildSourceUrls(): IImageSources | null {
    const { viewPreset, metadata } = this.props
    const buildSizedUrls = (format: `jpeg` | `webp`): ISizedUrls => {
      const ret: ISizedUrls = {}
      for (const mul of supportedDprs) {
        const w = Math.round(viewPreset.widthPx * mul)
        const h = viewPreset.isFixed ? Math.round(viewPreset.heightPx * mul) : 0
        ret[mul] = fixedSizeUrl(metadata, w, h, format, mul)
      }
      return ret
    }

    return {
      originalSrc: originalSizeUrl(metadata),
      jpeg: buildSizedUrls(`jpeg`),
      webp: buildSizedUrls(`webp`),
    }
  }

  public render() {
    const { metadata, viewPreset, alt, description, isLazy } = this.props

    const sources = this.buildSourceUrls()

    const haveLqip = metadata.lqipBase64 && metadata.lqipBase64.length
    let base64Lqip: string
    if (haveLqip) {
      base64Lqip = `data:image/${metadata.lqipType === `jpeg` ? `jpeg` : `svg+xml`};base64,${metadata.lqipBase64}`
    }
    const src = sources.jpeg[2] // x2 ratio by default
    const srcWebp = sources.webp[2]

    let img: JSX.Element
    if (viewPreset.isFixed) {
      const elem = <GatsbyImage
      fixed={{
        width: viewPreset.widthPx,
        height: viewPreset.heightPx,
        src,
        srcWebp,
        srcSet: supportedDprs.map((mul) => `${sources.jpeg[mul]} ${mul}x`).join(`,`),
        srcSetWebp: supportedDprs.map((mul) => `${sources.webp[mul]} ${mul}x`).join(`,`),
        base64: base64Lqip,
      }}
      loading="eager"
      imgStyle={{
        objectFit: `contain`,
        width: `${viewPreset.widthPx}px`,
        height: `${viewPreset.heightPx}px`,
      }}
    />

      img = isLazy ? (
        <LazyLoad offset={200} height={viewPreset.heightPx} once>
          {elem}
        </LazyLoad>
      ) : elem
    } else {
      const commonFluidParams = {
        aspectRatio: metadata.width && metadata.height ? metadata.width / metadata.height : 1,
        src: sources.jpeg[2], // x2 ratio by default
        srcWebp: sources.webp[2],
        srcSet: supportedDprs.map((mul) => `${sources.jpeg[mul]} ${Math.round(viewPreset.widthPx * mul)}w`).join(`, `),
        srcSetWebp: supportedDprs.map((mul) => `${sources.webp[mul]} ${Math.round(viewPreset.widthPx * mul)}w`).join(`, `),
        base64: base64Lqip,
      }

      const minAspectRatio = 0.7
      let maxHeight
      if (commonFluidParams.aspectRatio < minAspectRatio && metadata.height && metadata.width) {
        maxHeight = metadata.width / minAspectRatio
        commonFluidParams.aspectRatio = minAspectRatio
      }

      // No lazy load because GatsbyImage should make image height with padding hack.
      img = (
        <GatsbyImage
          fluid={[
            {
              ...commonFluidParams,
              media: `(max-width: ${viewPreset.widthPx}px)`,
              sizes: `100vw`,
            },
            {
              ...commonFluidParams,
              media: `(min-width: ${viewPreset.widthPx + 1}px)`,
              sizes: `${viewPreset.widthPx}px`,
            },
          ]}
          loading={isLazy ? "lazy" : "eager"}
          imgStyle={{
            objectFit: `cover`,
            maxWidth: `100%`,
            maxHeight,
          }}
        />
      )
    }

    // TODO: make originalUrl point to not resized but compressed image for SEO.

    // <noscript> is important for SEO
    return (
      <Box
        as="div"
        itemScope
        itemType="http://schema.org/ImageObject"
        minWidth={viewPreset.isFixed ? `${viewPreset.widthPx}px` : undefined}
        maxHeight={viewPreset.isFixed ? `${viewPreset.heightPx}px` : undefined}
      >
        <ItemProp name="name" value={alt} />
        <ItemProp name="description" value={description} />
        <ItemProp name="contentUrl" value={sources.originalSrc} />
        <a href={sources.originalSrc} onClick={this.onClick}>
          {img}
        </a>
        <noscript>
          <img width={viewPreset.widthPx} height={viewPreset.heightPx} src={sources.originalSrc} alt={alt} />
        </noscript>
      </Box>
    )
  }
}

export default PetImage
