import React from "react"
import loadable from "@loadable/component"

import { push, Push } from "connected-react-router"
import {
  Box,
  Flex,
  Text,
  Divider,
  Button,
  Alert,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/core"
import PetImage, { viewPresetDesktop, viewPresetMobile } from "components/lib/PetImage"
import { ICategoryProps, CategoryLink } from "components/lib/CategoryLink"
import { IAd, IImageMetadata } from "modules/ads/ads.reducers"
import { formatDistanceToNow, parseISO } from "date-fns"
import { ru } from "date-fns/locale"
import { FaEye, FaMousePointer, FaMapMarker } from "react-icons/fa"
import styled from "modules/utils/theme"
import { connect } from "react-redux"
import { toggle } from "modules/toggle"
import { IAppStore } from "reducers"
import { selectImage, adFinish } from "modules/ads/ads.actions"
import { makePlaceholderImageMetadata, originalSizeUrl } from "modules/ads/images"
import { IImageView } from "./ImageSlider"
import Link from "components/lib/Link"

const ImageSlider = loadable(() => import("./ImageSlider"))

export interface IPetProperty {
  id: string
  name: string
  value: string
}

export interface IPetCardProps {
  adId: number
  link: string
  title: string
  mainImage: IImageMetadata
  additionalImages: IImageMetadata[]
  description: string
  properties: IPetProperty[]
  category: ICategoryProps
  place: ICategoryProps
  animalType: string
  createdAt: string
  inListViewsCount: number
  viewsCount: number
  short?: boolean
  finishedAt?: string
  finishedReason?: string
  isLazy: boolean
}

const toggleKeyModalOpen = `PetCard/Images/Modal/Open`
const toggleKeyFinishModalOpen = `PetCard/Finish/Modal`

interface IStateProps {
  isModalOpen: boolean
  isFinishModalOpen: boolean
  selectedImageIndex?: number
  isAdFinishing?: boolean
}

interface IDispatchProps {
  push: Push
  toggle(name: string, value?: boolean): void
  selectImage(index: number): void
  adFinish(adId: number): void
}

export const makePetCardProps = (ad: IAd, placeName: string): IPetCardProps => {
  const category = {
    name: ad.category,
    url: `/${placeName}/${ad.category}`,
  }
  const place = placeName
    ? {
        name: placeName,
        url: `/${placeName}`,
      }
    : null

  const propertiesConfig = {
    name: "Кличка",
    gender: "Пол",
    color: "Окрас",
    signs: "Приметы",
    reward: "Вонаграждение",
    phone: "Контакты",
    breed: "Порода",
    placeDescription: "Место пропажи",
  }
  const properties: IPetProperty[] = []
  for (const p of Object.keys(propertiesConfig)) {
    const v = (ad as any)[p]
    if (!v) {
      continue
    }

    properties.push({
      id: p,
      name: (propertiesConfig as any)[p],
      value: v,
    })
  }

  const ret: IPetCardProps = {
    adId: ad.id,
    link: `/ads/${ad.id}`,
    title: ad.title,
    mainImage: ad.mainImage,
    additionalImages: ad.additionalImages,
    description: ad.description,
    properties,
    category,
    animalType: ad.animalType,
    place,
    createdAt: ad.createdAt,
    inListViewsCount: ad.inListViewsCount,
    viewsCount: ad.viewsCount,
    finishedAt: ad.finishedAt,
    finishedReason: ad.finishedReason,
  }
  return ret
}

const ResponsiveTableContainer = ({ children }: any) => <Box overflowX="auto">{children}</Box>

const TableRow = styled.tr`
  border-bottom: ${(p) => `${p.theme.borders["1px"]} ${p.theme.colors.gray[300]}`};
  &:hover {
    background-color: ${(p) => p.theme.colors.gray[100]};
  }
`

const TableCell = styled.td`
  padding-top: ${(p) => p.theme.space[2]};
  padding-bottom: ${(p) => p.theme.space[2]};
`

class PetCard extends React.PureComponent<IPetCardProps & IStateProps & IDispatchProps> {
  private renderTextBlock() {
    const lines = this.props.description.split(`\n`).map((descLine) => (
      <p>
        <Text overflowWrap="anywhere">{descLine}</Text>
      </p>
    ))
    return (
      <Box marginLeft={4}>
        {lines}

        <Box>{this.renderPropsTable()}</Box>
        {!this.props.short && this.renderFinishBlock()}
      </Box>
    )
  }

  private renderFinishBlock() {
    const isExpired = this.props.finishedReason === `expired`
    return (
      <>
        <Box mt={2}>
          {this.props.finishedAt ? (
            <Alert status={isExpired ? `info` : `success`} height="100%">
              <Text fontSize="lg">{isExpired ? `Объявление закрыто` : `Поиск успешно завершен!`}</Text>
            </Alert>
          ) : (
            <Button isLoading={this.props.isAdFinishing} onClick={this.openFinishModal}>
              Закрыть объявление
            </Button>
          )}
        </Box>
        <Modal isOpen={this.props.isFinishModalOpen} onClose={this.cancelFinishModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Закрыть объявление</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                При закрытии объявление будет скрыто в результатах поиска. Но его можно будет просмотреть по прямой ссылке и найти в разделе{" "}
                <Link href="/Все города?finished=yes" target="_blank">
                  завершенных поисков
                </Link>
                . Также будут скрыты контактные данные объявления.
              </Text>
              <br />
              <Text>
                Для защиты от ошибок, перед закрытием объявления мы просим вас написать комментарий к данной странице. В комментарии
                укажите, пожалуйста, что питомца / хозяина нашли. После этого еще раз нажмите на <b>Закрыть объявление</b> и затем на{" "}
                <b>Продолжить</b> для завершения закрытия.
              </Text>
            </ModalBody>

            <Box display={{ base: `none`, md: `block` }}>
              <ModalFooter>
                <Button variantColor="blue" mr={3} onClick={this.acceptFinishModal}>
                  Продолжить
                </Button>
                <Button onClick={this.cancelFinishModal}>Написать комментарий</Button>
              </ModalFooter>
            </Box>
            <Box display={{ base: `block`, md: `none` }} mb={3} mt={1}>
              <Flex direction="column" alignItems="center">
                <Button variantColor="blue" onClick={this.acceptFinishModal}>
                  Продолжить
                </Button>
                <Button mt={3} onClick={this.cancelFinishModal}>
                  Написать комментарий
                </Button>
              </Flex>
            </Box>
          </ModalContent>
        </Modal>
      </>
    )
  }

  private renderPropsTable() {
    return (
      <ResponsiveTableContainer>
        <table>
          <tbody>
            {this.props.properties
              // hide phone if is finished
              .filter((p) => !this.props.finishedAt || p.id !== `phone`)
              .map((p) => (
                <TableRow key={p.name}>
                  <TableCell>
                    <Text marginRight={4} as="b">
                      {p.name}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text>{p.value}</Text>
                  </TableCell>
                </TableRow>
              ))}
          </tbody>
        </table>
      </ResponsiveTableContainer>
    )
  }

  private renderOneImage(
    metadata: IImageMetadata | null,
    alt: string,
    description: string,
    isMobile: boolean,
    key: number | string,
    isLazy: boolean,
    onClick: () => void
  ) {
    return (
      <PetImage
        key={key}
        isLazy={isLazy}
        metadata={metadata || makePlaceholderImageMetadata()}
        viewPreset={isMobile ? viewPresetMobile : viewPresetDesktop}
        alt={alt}
        description={description}
        onClick={onClick}
      />
    )
  }

  private iconStyle = { display: `inline` }

  private renderShortInfoRow() {
    const width = { base: `33%`, md: `25%` }
    const countersWidth = { base: `0%`, md: `25%` }
    const countersDisplay = { base: `none`, md: `block` }
    return (
      <>
        <Divider />
        <Flex>
          <Box width={width}>
            <Text>{formatDistanceToNow(parseISO(this.props.createdAt), { locale: ru })} назад</Text>
          </Box>
          <Box width={width}>
            <CategoryLink {...this.props.category} />
          </Box>
          {this.props.place && (
            <Box width={width}>
              <CategoryLink {...this.props.place} icon={<FaMapMarker style={this.iconStyle} />} />
            </Box>
          )}
          <Box width={countersWidth} display={countersDisplay}>
            <Flex>
              {this.props.inListViewsCount ? (
                <Text>
                  <FaEye style={this.iconStyle} /> {this.props.inListViewsCount}
                </Text>
              ) : null}
              {this.props.viewsCount ? (
                <Text marginLeft={3}>
                  <FaMousePointer style={this.iconStyle} /> {this.props.viewsCount}
                </Text>
              ) : null}
            </Flex>
          </Box>
        </Flex>
      </>
    )
  }

  private onMainImageClick = () => {
    if (this.props.short) {
      // index (list) page
      this.props.push(this.props.link)
      return
    }

    this.props.selectImage(0)
    this.props.toggle(toggleKeyModalOpen, true)
  }

  private onExtraImageClick = (index: number) => {
    this.props.selectImage(index + 1) // +1 becausemain image has index 0
    this.props.toggle(toggleKeyModalOpen, true)
  }

  private closeModal = () => this.props.toggle(toggleKeyModalOpen, false)

  private openFinishModal = () => {
    this.props.toggle(toggleKeyFinishModalOpen, true)
  }
  private cancelFinishModal = () => {
    this.props.toggle(toggleKeyFinishModalOpen, false)
  }
  private acceptFinishModal = () => {
    this.props.toggle(toggleKeyFinishModalOpen, false)
    this.props.adFinish(this.props.adId)
  }

  private renderAdditionalImages() {
    return this.props.additionalImages.length ? (
      <Box>
        <Divider />
        <Flex wrap="wrap">
          {this.props.additionalImages.map((img, i) => (
            <Box marginRight={2} marginBottom={2} key={i}>
              {this.renderOneImage(img, this.props.title, this.props.description, false /* render as fixed desktop image */, i, true /* isLazy */, () =>
                this.onExtraImageClick(i)
              )}
            </Box>
          ))}
        </Flex>
      </Box>
    ) : null
  }

  public render() {
    const imageViews: IImageView[] = []
    if (this.props.mainImage) {
      imageViews.push({ source: originalSizeUrl(this.props.mainImage) })
    }
    for (const img of this.props.additionalImages) {
      imageViews.push({ source: originalSizeUrl(img) })
    }

    return (
      <Box>
        <Box display={{ base: `none`, md: `block` }}>
          <Flex>
            <Box>
              {this.renderOneImage(
                this.props.mainImage,
                this.props.title,
                this.props.description,
                false /* isMobile */,
                `desktop`,
                true, // overwrite isLazy=true because nolazy is critical only for mobile and otherwise we have double image loading on mobile
                this.onMainImageClick
              )}
            </Box>
            <Box>{this.renderTextBlock()}</Box>
          </Flex>
        </Box>
        <Box display={{ base: `block`, md: `none` }}>
          <Flex justifyContent="center">
            <Box width="100%" maxWidth={`${viewPresetMobile.widthPx}px`}>
              {this.renderOneImage(
                this.props.mainImage,
                this.props.title,
                this.props.description,
                true /* isMobile */,
                `mobile`,
                this.props.isLazy, // currently true means double image loading on desktop, no time to fix it.
                this.onMainImageClick
              )}
            </Box>
          </Flex>
          <Box marginTop={2}>{this.renderTextBlock()}</Box>
        </Box>

        <Box>{this.renderShortInfoRow()}</Box>
        <Box>
          {!this.props.short && this.props.mainImage && (
            <>
              {this.renderAdditionalImages()}
              <ImageSlider
                isModalOpen={this.props.isModalOpen}
                onClose={this.closeModal}
                currentIndex={this.props.selectedImageIndex}
                views={imageViews}
              />
            </>
          )}
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = (state: IAppStore): IStateProps => {
  return {
    isModalOpen: state.toggle.store[toggleKeyModalOpen],
    isFinishModalOpen: state.toggle.store[toggleKeyFinishModalOpen],
    selectedImageIndex: state.ads.selectedImageIndex,
    isAdFinishing: state.ads.isAdFinishing,
  }
}

const mapDispatchToProps = {
  push,
  toggle,
  selectImage,
  adFinish,
}

export default connect<IStateProps, IDispatchProps, IPetCardProps>(mapStateToProps, mapDispatchToProps)(PetCard)
