import React from "react"
import LazyLoad from "react-lazyload"
import VkLoader from "./VkLoader"

interface IProps {
  maxHeight?: number
}

class VkCommentsBrowser extends React.PureComponent<IProps> {
  private readonly divId = "vk_comments_browse"

  public render() {
    return (
      <>
        <div id={this.divId} />
        <VkLoader onLoad={this.initBrowser} />
      </>
    )
  }

  private initBrowser = (widgets: any) => {
    console.log("initializing VkCommentsBrowser")
    if (widgets) {
      widgets.CommentsBrowse(this.divId, {
        limit: 5,
        height: this.props.maxHeight || undefined,
        mini: `auto`,
      })
    }
  }
}

// Use lazyload not only because it's lazy but also because it doesn't render elements with hidden (display: none) parent.
// It saves us from unneeded requests on mobile devices when vk isn't visible.
export default (props: IProps) => (
  <LazyLoad height={props.maxHeight || undefined}>
    <VkCommentsBrowser {...props} />
  </LazyLoad>
)
