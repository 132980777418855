import { IImageMetadata } from "./ads.reducers"

const gsPrefix = `gs://`

const devImageUrl = (metadata: IImageMetadata, sizeMultiplicator: number, format: `webp` | `jpeg`): string => {
  const { id, version } = metadata
  if (!id.startsWith(gsPrefix)) {
    throw Error(`invalid image id ${id} prefix`)
  }

  return `/cloud/images/${id.substr(gsPrefix.length)}?sizeMultiplicator=${sizeMultiplicator}&format=${format}${
    version ? `&v=${version}` : ``
  }`
}

export const originalSizeUrl = (metadata: IImageMetadata): string => {
  return fixedSizeUrl(metadata, 0, 0, `jpeg`, 0)
}

export const fixedSizeUrl = (
  metadata: IImageMetadata,
  neededWidth: number,
  neededHeight: number,
  format: `webp` | `jpeg`,
  sizeMultiplicator: number
): string => {
  if (__DEV__) {
    // No resizing in dev env to not setup imgproxy and nginx in dev.
    return devImageUrl(metadata, sizeMultiplicator, format)
  }

  const { id, version } = metadata

  if (!id.startsWith(gsPrefix)) {
    console.warn(`Unknown image id ${id}`)
    return ``
  }

  // sizeMultiplicator and format at the end used just to faster understand what image type was used
  // in a Chrome web panel because it shows only file names, without full path on primary network view.
  return `/v2/cloud/images/${neededWidth}:${neededHeight}:${format}/${id.slice(gsPrefix.length)}.x${sizeMultiplicator}.${format}${
    version ? `?v=${version}` : ``
  }`
}

export const makePlaceholderImageMetadata = (): IImageMetadata => ({
  id: `gs://dog_placeholder_v5.png`,
  width: 1024,
  height: 770,

  // Built by `npm install -g sqip && go get -u github.com/fogleman/primitive && sqip src/assets/dog_placeholder.jpg`
  lqipBase64: `PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDI0IDc3MCI+PGZpbHRlciBpZD0iYiI+PGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMTIiIC8+PC9maWx0ZXI+PHBhdGggZmlsbD0iI2Y2ZTFjZiIgZD0iTTAgMGgxMDI0djc2OEgweiIvPjxnIGZpbHRlcj0idXJsKCNiKSIgdHJhbnNmb3JtPSJtYXRyaXgoNCAwIDAgNCAyIDIpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjYzk1MjFiIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0zNS45NzgxNSAyMS4wMTMwOSAtMjYuNTg2NjkgLTQ1LjUyMTE1IDE3OS4yIDczLjMpIi8+PGVsbGlwc2Ugcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMTIuMjcyMiAtNC4xODk4OSA3LjQzMDY4IC0yMS43NjQ1IDIwLjYgODkuNCkiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDEyNS44NzM5NiA5LjY2MDUxIC00LjQ1MjY4IDU4LjAxNzI1IDkzIDE2NSkiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDEyLjk5NzI1IDM1LjUwNzU1IC05OS40MDc5MyAzNi4zODc0NyA0Ny44IDI0LjMpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgY3g9IjI0OCIgY3k9IjYiIHJ4PSIxMjgiIHJ5PSIyOSIvPjxlbGxpcHNlIGZpbGw9IiM2MDNjM2MiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTIyLjgwOTc1IDkuMTY5NDYgLTMuMjYzMzMgLTguMTE3OCA3LjIgODUpIi8+PHBhdGggZmlsbD0iI2Q2Nzg0NCIgZD0iTTIwNSAzMGwtMTYgMTA1IDM4LTgxeiIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoOTMuOTE4NDcgLTM5LjI4Njk1IDE1LjQ0NjU3IDM2LjkyNjIxIDE5LjYgMjguOCkiLz48L2c+PC9zdmc+`,
})

export const filenameForImage = (id: string): string => {
  const parts = id.split(`/`)
  return parts[parts.length - 1]
}
