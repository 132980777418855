import { sendAmplitudeEvent } from "./utils/events"

export const initWidgets = () => {
  const w = window as any
  const VK = w.VK
  if (!VK) {
    // TODO: send log error
    return
  }

  const vkWasInited = w.vkWasInited
  if (vkWasInited) {
    console.info(`vk was already inited, skipping initializing`)
    return VK.Widgets
  }

  VK.init({ apiId: 7511380, onlyWidgets: true })
  VK.Observer.subscribe(`widgets.comments.new_comment`, () => {
    sendAmplitudeEvent(`make_comment`, {})
  })
  w.vkWasInited = true
  console.log("initializing VK")
  return widgets()
}

export const widgets = () => {
  const VK = (window as any).VK
  return VK.Widgets
}
